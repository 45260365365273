import React, { useRef, useEffect, useState } from "react";


export default function Snapshot({ onSnapshot, widthPercent = 0.4 }) {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [imgData, setImgData] = useState("");
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  // Can we change to a getter? Why no access in useEffect?
  const hasCamera = () => {
    return navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
  };


  function getWindowDimensions() {
    const hasWindow = typeof window !== "undefined";
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;

    return {
      width,
      height,
    };
  }

  const [viewport, setViewport] = useState(getWindowDimensions());

  const width = viewport.width * widthPercent;
  const height = (width * 9) / 16;

  useEffect(() => {
    if (!videoRef) return;
    let _stream = undefined;

    if (
      !isMobile &&
      navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia
    ) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          _stream = stream
          const video = videoRef.current;
          video.srcObject = stream;
          video.play();
        });
    } else {
      // TODO display this on the page.
      console.log("Snapshot: no camera access");
    }

    return () => {
      if (_stream) _stream.getTracks().forEach((track) => track.stop());
    };
  }, [videoRef, isMobile]);

  const takeScreenshot = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const video = videoRef.current;

    context.drawImage(video, 0, 0, width, height);

    //video.style.display = "none";

    const dataUrl = canvas.toDataURL("image/png");

    setImgData(dataUrl);
    onSnapshot(dataUrl);
  };

  const clearScreenshot = () => {
    //video.style.display = "block";
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    setImgData("");
  };

  const handleImgSelect = (e) => {
    if (e.target.files && e.target.files[0]) {
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onloadend = () => {
        setImgData(reader.result);
        onSnapshot(dataUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      {isMobile ? (
        <>
          <input
            type="file"
            accept="image/*"
            capture="camera"
            onChange={(e) => handleImgSelect(e)}
          />
          {imgData && <img src={imgData} alt="User Profile" width={width} />}
        </>
      ) : (
        <>
          <canvas
            id="canvas"
            hidden={imgData ? false : true }
            ref={canvasRef}
            width={width}
            height={height}
          ></canvas>
          <video
            id="video"
            hidden={imgData ? true : false }
            ref={videoRef}
            width={width}
            height={height}
            autoPlay
          ></video>
          <div className="uk-flex uk-flex-center uk-margin-top">
            <a
              id="snap"
              className="uk-button uk-button-primary"
              onClick={takeScreenshot}
            >Snap Photo</a>
            {imgData && (
              <a
                className="uk-button uk-button-default"
                onClick={clearScreenshot}
              >Clear</a>
            )}
          </div>
        </>
      )}
    </>
  );
}
