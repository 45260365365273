import React, { useState, useEffect } from 'react';
import { Rnd } from "react-rnd";
import { PATCH } from "../../helpers";
import _ from "lodash";

// POSSIBLE TODO: There are 2 setItems called when a field is dragged, if
// they are the same, it would be nice to cancel the second one. Could do
// that via adding a useState for the last JSON string sent, and if it matches
// then cancel. OR debounce, or remove the setItems from update field, or use
// redux. - Matt

const defaultItem = {
  name: null,
  height: 50,
  percentHeight: 0,
  width: 200,
  percentWidth: 0,
  x: 0,
  percentX: 0,
  y: 0,
  percentY: 0,
  isDisplayed: false
};
const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "solid 1px #ddd",
  background: "#f0f0f0"
};


function CertificateEditor({ container }, ref) {
  const [template, setTemplate] = useState({
    src: "",
    height: 0
  });
  const [items, setItems] = useState([]);
  const [isInitialized, setInitialized] = useState(false);

  // basically constructor
  //
  // Main points - set height on canvas
  useEffect(async () => {
    if (!container) return;

    // side effect of this calls setTemplate
    const src = container.getAttribute("data-template");
    const height = await getTemplateHeight(src);

    setTemplate({ src, height });

    // positions are the server rendered json representation of fields
    // needs to be unified with 'items' which should be fields or something.
    // let positions = [];
    const fieldsJson = JSON.parse(container.getAttribute("data-fields"));
    console.log('RECEIEVED', fieldsJson);

    const availableFields = JSON.parse(container.getAttribute("data-available-fields"));

    // fieldNames = ['signature', 'attendee_name']
    const savedItems = availableFields.map((name) => {
      let fieldJson = fieldsJson[name];
      let item = undefined;

      if (fieldJson) {
        item = convertSavedItem(Object.assign(fieldJson, { name }), height);
      } else {
        item = Object.assign({}, defaultItem, { name });
      }
      return item;
    });

    setItems(savedItems);
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (!isInitialized) return;
    // because the draggable items are locked within the parent,
    // the percentX should never be beyond the template

    // should displayedFields
    const validItems = items.filter((item) => item.isDisplayed);
    if (!validItems || !validItems.length) {
      //updateServer();
      //return;
    }
    const fields = {};
    console.log(`validItems - `, validItems)
    validItems.forEach(
      (validItem) => {
        if (validItem.percentHeight === 0 || validItem.percentWidth === 0) {
          updateField(validItem.name, validItem)
          return;
        }
        fields[validItem.name] = {
          at: [validItem.percentX, validItem.percentY],
          size: [validItem.percentWidth, validItem.percentHeight],
        };
      }
    );

    updateServer(fields);
  }, [items]);


  // useEffect(() => {
  //   const handleResize =  _.debounce(() => {
  //     getTemplateHeight(container.getAttribute("data-template"));
  //     console.log('[RESIZED]');
  //   }, 500);
  //   window.addEventListener('resize', handleResize);
  //   return _ => {
  //     window.removeEventListener('resize', handleResize);
  //   }
  // });
  useEffect(() => {
    const handleResize = _.debounce(() => location.reload(), 500);
    window.addEventListener('resize', handleResize);
    return _ => {
      window.removeEventListener('resize', handleResize);
    }
  });

  async function loadImage(url, elem) {
    return new Promise((resolve, reject) => {
      elem.onload = () => resolve(elem);
      elem.onerror = reject;
      elem.src = url;
    });
  }
  // this means the certificate template image
  async function getTemplateHeight(src) {
    const canvasSize = getEleSizePos();
    const { width } = canvasSize;
    let height = undefined;

    if (!src || src.length === 0) {
      height = (width * 8.5) / 11;
    } else {
      const img = new Image();
      await loadImage(src, img)
      height = (width * img.height) / img.width;
    }
    return height;
  }

  // hook method
  function updateServer(value = {}) {
    console.log('ITEMS STATE', items);
    const data = { certificate_template: {
      fields: value,
    }};
    let json = JSON.stringify(data);
    //let json = JSON.stringify(value);
    let input = document.getElementById(
      "certificate_field_positions"
    )
    if (input) {
      input.value = json;
    } else {
      console.log('Patching JSON: ', json);
      PATCH(window.location.href, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: json
      })
      .then((response) => response.json())
      .then((data) => {
        const fields = data.certificate_template.fields;
        container.setAttribute('data-fields', fields);
      });
    }
  }

  // getBoundingRect(id = "draggable-area")
  function getEleSizePos(id = "draggable-area") {
    const el = document.getElementById(id);
    return !el ? null : el.getBoundingClientRect();
  }

  function updateField(name, dimensions) {
    const fieldNameIndex = items.findIndex((index) => index.name === name)
    const tempItems = [...items];
    const item = items[fieldNameIndex];
    const canvasSize = getEleSizePos();
    const itemSize = getEleSizePos(`draggable-item-${name}`);

    item.x = dimensions.x;
    item.y = dimensions.y;
    item.width = dimensions.width || itemSize.width;
    item.height = dimensions.height || itemSize.height;
    item.percentHeight = dimensions.percentHeight || (itemSize.height / canvasSize.height < 1 ? itemSize.height / canvasSize.height : .10);
    item.percentWidth = dimensions.percentWidth || (itemSize.width / canvasSize.width < 1 ? itemSize.width / canvasSize.width : .20);
    item.percentX = dimensions.percentX || ((itemSize.x - canvasSize.x) / canvasSize.width);
    item.percentY = dimensions.percentY || ((canvasSize.y + canvasSize.height - itemSize.y) / canvasSize.height);

    tempItems[fieldNameIndex] = item
    setItems(tempItems);
  }

  function convertSavedItem(fieldJson, canvasHeight) {
    const [percentWidth, percentHeight] = fieldJson.size;
    const [percentX, percentY] = fieldJson.at;
    const { height: bgHeight, width: bgWidth, } = Object.assign(getEleSizePos(), { height: canvasHeight });

    const height = bgHeight * percentHeight > 0 ? bgHeight * percentHeight : 50;
    const width = bgWidth * percentWidth > 0 ? bgWidth * percentWidth : 200;
    const x = percentX * bgWidth;
    const y = bgHeight - (percentY * bgHeight);

    const item = {
      name: fieldJson.name,
      height,
      percentHeight,
      width,
      percentWidth,
      x,
      percentX,
      y,
      percentY,
      isDisplayed: true
    };
    return item;
  }


  function renderFields() {
    return items.filter((item) => item.isDisplayed).map((item) => (
      <Rnd
        key={item.name}
        id={`draggable-item-${item.name}`}
        style={style}
        default={{
          x: item.x,
          y: item.y,
          width: item.width,
          height: item.height
        }}
        minWidth={"10%"}
        minHeight={"4%"}
        onResizeStop={(e) => updateField(item.name, e)}
        onDragStop={(e) => updateField(item.name, e)}
        bounds="parent"
      >
        {item.name}
      </Rnd>
    ))
  }

  console.log('[RENDER]');
  return (
    <div className="uk-grid" data-uk-gridx="">
      {items.length ? (
        <div className="col uk-width-1-4@m">
          <h4>Fields</h4>
          {items.map((item, index) => (
            <div key={item.name}>
              <input
                type="checkbox"
                id={item.name}
                name={item.name}
                value={item.isDisplayed}
                onChange={() => {
                  const tempItems = [...items];
                  tempItems[index].isDisplayed = !tempItems[index].isDisplayed;
                  setItems(tempItems);
                }}
                checked={item.isDisplayed}
              />
              <label htmlFor={item.name}>{item.name}</label>
            </div>
          ))}
        </div>
      ) : (<></>)}

      <div
        className="col uk-width-3-4@m uk-title uk-tile-muted uk-padding-remove"
        id="draggable-area"
        style={{
          backgroundImage: template.src ? `url(${template.src})` : "none",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top left",
          height: template.height ? `${template.height}px` : "auto",
          position: "relative",
        }}>
        {items.length && renderFields()}
      </div>

    </div>
  );
}

export default CertificateEditor;
