import React, { useRef, useState, useEffect } from "react";
import SignaturePad from "react-signature-canvas";
// https://www.npmjs.com/package/react-signature-canvas
//import styles from "./signature.module.css";

export default function SignatureWidget({selector, onSignatureSave, widthPercent = 0.4}) {
  const sigCanvas = useRef({});
  const [src, setSrc] = useState("");

  const hasWindow = typeof window !== 'undefined';

  useEffect(() => {
    return () => {
      console.log('UNMOUNT SignatureWidget');
    };
  }, []);

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;

    return {
      width,
      height,
    };
  }

  const [
    viewport,
    setViewport
  ] = useState(getWindowDimensions());

  const clear = () => {
    sigCanvas.current.clear();
    setSrc("");
    save();
  };

  const save = () => {
    const inputEl = document.querySelector(selector);
    //const base64Str = sigCanvas.current
    //.getTrimmedCanvas()
    //.toDataURL("image/png");
    const base64Str = sigCanvas.current.toDataURL('image/png');

    setSrc(base64Str);

    if (onSignatureSave) onSignatureSave(base64Str);
    if (inputEl) inputEl.value = base64Str;
  };

  const width = viewport.width * widthPercent;

  return (
    <>
      <div className='signature-pad'>
        <SignaturePad
          ref={sigCanvas}
          canvasProps={{
            height: 200,
            width: width,
          }}
          onEnd={() => {
            save()
          }}
        />
      </div>
      <p className="uk-text-right">
        <a
          type="button"
          className="uk-button uk-button-text"
          onClick={clear}
        >Clear</a>
      </p>
    </>
  );
}

//<a
//  type="button"
//  className="uk-button uk-button-link uk-margin-small-left"
//  onClick={save}
//>Commit</a>
//<div>
//  {src && (
//    <img src={src}  alt="signature output" />
//  )}
//</div>
