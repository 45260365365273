// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from "react";
import ReactDOM from "react-dom";
//import PropTypes from 'prop-types'
import CertificateEditor from "../Components/CertificateEditor";
import SignaturePad from '../Components/SignaturePad'
import Snapshot from '../Components/Snapshot'
import Tagger from '../Components/Tagger'
import { ready } from "../helpers";

const handleSnapshot = (data) => {
  //console.log('handleSnapshot', data);
  const input = document.querySelector('#attendance-photo');
  if (input) input.value = data;
  console.log('handleSnapshot', input.value);
}

window.App = window.App || {};
window.App.ReactDOM = ReactDOM;

window.App.initTagger = function () {
  const tagger = document.getElementById("course-tags");
  if (tagger) {
    ReactDOM.render(
      <Tagger />,
      tagger
    );
  }
}


window.App.initCertEditor = function () {
  let editor = document.getElementById("certificate-editor");
  // Want to remove container and just pass the data on the element into the
  // application
  //
  if (editor) {
    ReactDOM.render(
      <React.StrictMode>
        <CertificateEditor container={editor} />
      </React.StrictMode>,
      editor
    );
  }
};

window.App.initProfileSignature = function() {
  const profileSignature = document.getElementById("profile-signature-app");
  if (profileSignature) {
    ReactDOM.render(
      <SignaturePad
        selector="#profile-signature"
        widthPercent={.4}
      />,
      profileSignature
    );
  }

};

window.App.initRollcallModal = function(container) {
  const snapshot = document.getElementById("snapshot-app");
  if (snapshot) {
    ReactDOM.render(
      <Snapshot onSnapshot={handleSnapshot} widthPercent={.5} />,
      snapshot
    );
  const profileSignature = document.getElementById("rollcall-signature-app");
  if (profileSignature) {
    ReactDOM.render(
      <SignaturePad
        selector="#attendance-signature"
        widthPercent={.5}
      />,
      profileSignature
    );
  }
  }
};

ready(() => {

  App.initCertEditor();
  App.initProfileSignature();

  const handleSignature = (data) => {
    console.log('handleSignature', data);
  }

  const signature = document.getElementById("signature-app");
  if (signature) {
    console.log(signature)
    ReactDOM.render(
      <SignaturePad selector="#user_signature" onSignatureSave={handleSignature} widthPercent={.4} />,
      signature
    );
  }

  UIkit.util.on(document, 'beforeshow', '#attendance-modal', () => {
    const snapshot = document.getElementById("snapshot-app");
    if (snapshot) {
      ReactDOM.render(
        <Snapshot onSnapshot={handleSnapshot} widthPercent={.4} />,
        snapshot
      );
    }
  });

  UIkit.util.on(document, 'beforehide', '#attendance-modal', () => {
    const snapshot = document.getElementById("snapshot-app");
    ReactDOM.unmountComponentAtNode(snapshot);
  });

  UIkit.util.on(document, 'beforehide', '#rollcall-attendance-modal', () => {
    const snapshot = document.getElementById("snapshot-app");
    ReactDOM.unmountComponentAtNode(snapshot);
  });

  window.App.initTagger()

});
