import React from "react";
import ReactTags from "react-tag-autocomplete";
import { GET } from "../../helpers";

class Tagger extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      busy: false,
      tags: [],
      suggestions: [],
    };

    this.reactTags = React.createRef();
  }

  componentDidMount() {
    let tags = document.querySelector("#course-tags").dataset.tags;
    if (tags) {
      tags = JSON.parse(tags) || [];
      this.setState({ tags });
    }
  }

  updateHiddenInput = (tags) => {
    const hiddenInput = document.querySelector("#course_tag_ids");
    const idArr = tags.map((tag) => tag.id);
    hiddenInput.value = JSON.stringify(idArr);
  };

  onDelete = (idx) => {
    const tags = this.state.tags.slice(0);
    tags.splice(idx, 1);
    this.setState({ tags });
    this.updateHiddenInput(tags);
  };

  onAddition = (tag) => {
    const tagExists = this.state.tags.some((item) => item.id === tag.id);
    if (!tagExists) {
      const tags = [].concat(this.state.tags, tag);
      this.setState({ tags });
      this.updateHiddenInput(tags);
    }
  };

  handleInputChange = (query) => {
    if (!this.state.busy && query.length >= 1) {
      this.setState({ busy: true }, async () => {
        const { data } = await this.queryTags(query);
        this.setState({ suggestions: data, busy: false });
      });
    }
  };

  queryTags = (val) => {
    const p = new URLSearchParams();
    p.set("query", val);
    return GET(`/admin/tags?${p.toString()}`, {
      headers: { Accept: "application/json" },
    }).then((res) => res.json());
  };

  render() {
    return (
      <>
        <ReactTags
          ref={this.reactTags}
          tags={this.state.tags}
          suggestions={this.state.suggestions}
          minQueryLength={1}
          removeButtonText="Click to remove this tag."
          noSuggestionsText="No matching tags."
          allowBackspace={false}
          onDelete={this.onDelete}
          onAddition={this.onAddition}
          onInput={this.handleInputChange}
        />
      </>
    );
  }
}
export default Tagger;
